import { Container } from "@chakra-ui/react"
import React from "react"

import DownloadForm from '../downloadForm'

const paragraphs = require('lines-to-paragraphs');

const DownloadBlock = ({ content, ...rest }) => {
  var text = content.description 
  return (
    <Container
      variant="Text"
      fontSize={[16, 16, 18, 18]}
      marginLeft={0}
      marginRight={0}
      paddingLeft={0}
      paddingRight={0}
      maxWidth="100%"
      {...rest}
      // dangerouslySetInnerHTML={{ __html: paragraphs(text) }}
    >
      {/* {content.description} */}
      <DownloadForm showMessag={false}/>
    </Container>
  )
}

export default DownloadBlock
