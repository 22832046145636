import React from "react"

import PageSection from "../pageSection/pageSection"
import { Box } from "@chakra-ui/react"

const Page = ({ page, ...rest }) => {
  const sections = page.sections
  return (
    // <Center id="Center" minHeight="70vh" height="100%">
    <Box 
    id="pageSections" {...rest}
    minHeight="70vh"
    >
      {sections.map((section, index) => {
        return (
          <PageSection key={index} section={section} minHeight="100%"/>
        )
      })}
    </Box>
    // </Center>
  )
}


export default Page
