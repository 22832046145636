import React from "react"
import { graphql } from "gatsby"

// import { Box } from "@chakra-ui/react"
import Layout from "../../components/layout"
import Page from "../../components/page/page"
import './page.sass'
// import { TransitionState } from "gatsby-plugin-transition-link"

export const data = graphql`
  query($slug: String!) {    
    contentfulPage(slug: {eq: $slug}) {
      slug
      title
      metaTitle
      metaDescription
      showFooter
      titlePosition
      metaLogo {
        fixed {
          ...GatsbyContentfulFixed
        }
      }
      navigationBar {
        showBurgerBarOnly
        logo {
          fixed {
            ...GatsbyContentfulFixed
          }
          fluid(maxWidth: 200) {
            ...GatsbyContentfulFluid
          }
        }
        navbarItems {
          linkTo {
            title
            slug
          }
        }
      }
      bgImage {
        image {
          fluid(maxWidth: 2048) {
            ...GatsbyContentfulFluid
          }
        }
      }
      sections {
        title
        project44title
        centreTitle
        responsiveSections
        sectionLink {
          altText
          internalLink {
            slug
          }
        }
        section_components {
          __typename
          ... on Node {
            ... on ContentfulComponentButton {
              buttonText
            }
            ... on ContentfulComponentDownloadBlock {
              name
            }
            ... on ContentfulComponentImageBlock {
              columnSpan
              externalLink
              name
              image {
                ... on ContentfulMedia {
                  media {
                    fixed {
                      ...GatsbyContentfulFixed
                    }
                    fluid {
                      ...GatsbyContentfulFluid
                    }
                  }
                }                
              }
            }
            ... on ContentfulComponentTextBlock {
              columnSpan
              text {
                text
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
  }
`

const Pages = props => {
  return (
    // <TransitionState>
    <Layout id="pageTemplate"
      showFooter={props.data.contentfulPage.showFooter}
      navbar={props.data.contentfulPage.navigationBar}
      slug={props.pageContext.slug}
      bgImage={props.data.contentfulPage.bgImage}
      titlePosition={props.data.contentfulPage.titlePosition}
      title={props.data.contentfulPage.title}
      metaTitle={props.data.contentfulPage.metaTitle}
      metaImage={props.data.contentfulPage.metaLogo.fixed}
      metaDescription={props.data.contentfulPage.metaDescription}
      height="80%">
      <Page page={props.data.contentfulPage} />
    </Layout>
    // </TransitionState>
  )
}

export default Pages
