import { Container } from "@chakra-ui/react"
import React from "react"

const paragraphs = require('lines-to-paragraphs');

const TextBlock = ({ content, ...rest }) => {
  var text = content.text ? content.text.childMarkdownRemark.html : ""
  return (
    <Container
      variant="Text"
      fontSize={[16, 16, 18, 18]}
      marginLeft={0}
      marginRight={0}
      paddingLeft={0}
      paddingRight={0}
      maxWidth="100%"
      {...rest}
      dangerouslySetInnerHTML={{ __html: paragraphs(text) }}
    >
      {/* {content.text.text} */}
    </Container>
  )
}

export default TextBlock
