
import { Link } from "@chakra-ui/react"
import React from "react"

const ExternalLink = ({ url, children, alt }) => {

    return (
        <>
            { url ?
                <Link href={url} isExternal alt={alt}>
                    {children}
                </Link>
                :
                { children }
            }
        </>
    )
}

export default ExternalLink
