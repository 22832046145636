import { useForm } from "react-hook-form";
import React, { useState } from "react";
import Button from './button'
import API from '@aws-amplify/api';
import {
    FormLabel,
    FormControl,
    Spinner,
    Input,
    Container,
    Box,
    Text
} from "@chakra-ui/react";

export default function DownloadForm() {
    const { handleSubmit, errors, clearErrors, setError, register, formState } = useForm();
    const [downloadURL, setDownloadURL] = useState(false)

    function removeError() {
        setInterval(
            function () { clearErrors(); },
            2500
        );
    }
    function onSubmit(values) {
        var data = {
            body: values,
            headers: {
                "Content-Type": "application/json"
            }
        };
        console.log("VALUES", data)
        setError("submitting", { status: true });
        API
            .post('api', '/email/download', data)
            .then(response => {

                console.log("API RESPONSE", response)
                setDownloadURL(response.s3GetObjectUrl)
                setError("submitting", { status: false });

            })
            .catch(error => {
                setError("submitting", { status: false });
                console.log("API ERROR", error)
                setError("submitting", false);
                setError("message", {
                    type: "submit",
                    message: "Failed to submit download request."
                });
                
                removeError()
            })
    }

    return (
        <Container width={["100%", "100%", "80%", "80%"]}>
            {/* <Center height="100vh" width="100%"> */}
            {downloadURL ?
                <>
                    <a href={downloadURL} download>
                        <Button buttonText="Download Insights Report" mt={10} type="submit"></Button>
                    </a>
                </>
                :
                <Box width={"100%"} as="form" onSubmit={handleSubmit(onSubmit)}>
                    <FormControl isInvalid={errors.name}>
                        <FormLabel marginTop={3} htmlFor="firstname"><Text variant="Label">First Name</Text></FormLabel>
                        <Input marginBottom={0}
                            borderColor="brandYellow.50"
                            name="firstname"
                            placeholder="First Name"
                            ref={register({ required: true })}
                        // ref={register({ validate: validateName })}
                        />
                        {errors.firstname && <Container variant="Error">First Name Required</Container>}
                        <FormLabel marginTop={3} htmlFor="surname"><Text variant="Label">Surname</Text></FormLabel>
                        <Input marginBottom={0}
                            borderColor="brandYellow.50"
                            name="surname"
                            placeholder="Surname"
                            ref={register({ required: true })}
                        // ref={register({ validate: validateName })}
                        />
                        {errors.firstname && <Container variant="Error">Surname Required</Container>}
                        <FormLabel marginTop={3} htmlFor="role"><Text variant="Label">Role</Text></FormLabel>
                        <Input
                            borderColor="brandYellow.50"
                            marginBottom={0}
                            name="role"
                            placeholder="Role"
                            ref={register({ required: true })}
                        />
                        {errors.surname && <Container variant="Error">Role Required</Container>}
                        <FormLabel marginTop={3} htmlFor="company"><Text variant="Label">Company</Text></FormLabel>
                        <Input
                            borderColor="brandYellow.50"
                            marginBottom={0}
                            name="company"
                            placeholder="Company"
                            ref={register({ required: true })}
                        />
                        {errors.surname && <Container variant="Error">Company Required</Container>}
                        <FormLabel marginTop={3} htmlFor="email"><Text variant="Label">eMail Address</Text></FormLabel>
                        <Input
                            borderColor="brandYellow.50"
                            marginBottom={0}
                            name="email"
                            placeholder="eMail Address"
                            ref={register({
                                required: "Required",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "invalid email address"
                                }
                            })}
                        />
                        {errors.email &&
                            <Container variant="Error">Invalid eMail address</Container>
                        }
                        {errors.message &&
                            <Container variant="Error">{errors.message.message}</Container>
                        }

                    </FormControl>
                    {errors.submitting && errors.submitting.status ?
                        <Container mt={5}>    
                            <Spinner color="brandWhite.500" />
                        </Container>
                        :
                        <Button buttonText="Download" mt={10} isLoading={formState.isSubmitting} type="submit">
                        </Button>
                    }



                </Box>
            }
            {/* </Center> */}
        </Container>
    );
}